body {
  background-image: url('./images/garden-transparent.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-container {

  background-color: rgba(255, 255, 255, .9);

  padding: 50px;
  border-radius: 5px;
  margin-top: 1em;
}

